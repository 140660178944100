import DOMPurify from 'dompurify';

import {
  ATTRIBUTE_MODAL_DESCRIPTION,
  ATTRIBUTE_MODAL_LOGO_SRC,
  ATTRIBUTE_MODAL_TITLE,
} from '../../../constants/loginButton';
import {ModalCustomizations} from '../../../types';

export const MAX_TITLE_LENGTH = 150;
export const MAX_DESCRIPTION_LENGTH = 300;

/**
 *
 * @param {ModalCustomizations} modalCustomizations The customized content of the modal.
 * @param {string} apiKey The API key provided to look up the client.
 */
export function validateModalCustomizations(
  {modalTitle, modalDescription, modalLogo}: ModalCustomizations,
  apiKey?: string,
) {
  const isModalCustomized = Boolean(
    modalTitle || modalDescription || modalLogo,
  );
  const isConfigValid = !isModalCustomized || apiKey;
  if (!isConfigValid) {
    throw new Error(
      `An API key must be provided if the modal content is customized`,
    );
  }

  if (modalTitle && modalTitle.length > MAX_TITLE_LENGTH) {
    throw new Error(
      `{ATTRIBUTE_MODAL_TITLE} cannot exceed {MAX_TITLE_LENGTH} characters.`,
    );
  }

  if (modalDescription && modalDescription.length > MAX_DESCRIPTION_LENGTH) {
    throw new Error(
      `{ATTRIBUTE_MODAL_DESCRIPTION} cannot exceed {MAX_DESCRIPTION_LENGTH} characters.`,
    );
  }

  if (modalLogo) {
    try {
      // eslint-disable-next-line no-new
      new URL(modalLogo);
    } catch (error) {
      throw new Error(`{ATTRIBUTE_MODAL_LOGO_SRC} must be a valid URL`);
    }
  }
}

/**
 * Sanitizes modal customization text to remove all HTML tags.
 * @param {string} text Modal customization text which can be any string.
 * @returns {string} Sanitized modal customization text which can be safely displayed on our modal.
 */
export function sanitizeModalText(text: string): string {
  if (!DOMPurify.isSupported) {
    throw new Error(
      'Sanitization of custom modal text is not supported in this browser.',
    );
  }
  return DOMPurify.sanitize(text, {ALLOWED_TAGS: []});
}
